import {
  Component,
  Vue,
} from 'vue-property-decorator';
import PageLayout from '@/layout/Page.vue';

@Component({
  components: {
    PageLayout,
  },
  metaInfo() {
    return this.$route.meta?.metaInfo;
  },
})
export default class Page404 extends Vue {}
